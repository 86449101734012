
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #212121;
  color: #fff;
}

.title {
  font-size: 50px;
  margin-bottom: 20px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.container{
  padding: 20px;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;

}
.uploadarea{
  margin-top: 50px;
  background-color: #333;
  padding: 20px;
}

.file-input {
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  padding: 30px;
  border: none;
  font-weight: 700;
  background-color: #333;
  color: #fff;
}

.upload-button {
  padding: 10px 20px;
  background-color: #b32e00;
  color: #fff;
  font-weight: 700;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
}

.upload-button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.processing-message, .error-message {
  margin-top: 10px;
}

.data-header {
  font-size: 20px;
  margin-top: 20px;
}

.download-button {
  padding: 10px 20px;
  background-color: #b32e00;
  color: #fff;
  border: none;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
}

.csv-format-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.csv-format-table th,
.csv-format-table td {
  border: 1px solid #333;
  padding: 8px;
}


.csv-format-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #b32e00;
  font-weight: 700;
  color: white;
}